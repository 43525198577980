import moment from 'moment'; // Included with Angular CLI.

// @ts-ignore
// Will be removed in Dolphin release after approve from Jonathan [year format will be changed to YYYY]
moment.parseTwoDigitYear = (year) => {
  if (year.length > 2) {
    return (parseInt(year, 10) % 10) + 2000;
  }

  return parseInt(year, 10) + 2000;
};
